import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function PopUpRegistrationSuccess({ text }) {
  return (
    <div className="bg-gray-750 flex items-center py-3 px-4 mx-2 md:mx-36 rounded-lg mt-8 my-4">
      <FontAwesomeIcon icon={faCheckCircle} className="text-white w-5 h-5" />
      <p className="text-white text-sm ml-2 font-semibold">
        {/* Your account has been created successfully and you can register for the tournament. */}
        {text}
      </p>
    </div>
  );
}

export function PopUpNotVerified() {
  return (
    <div className="bg-gray-750 flex items-center py-3 px-4 mx-36 rounded-lg mt-8 my-4">
      <FontAwesomeIcon icon={faExclamationCircle} className="text-white w-5 h-5" />
      <p className="text-white text-sm ml-2 font-semibold">
        You cannot create team until you're verified.
      </p>
    </div>
  );
}
